<template>
  <div>
    <b-card v-if='!isDataLoaded'>
      <div class='text-center text-success my-2'>
        <b-spinner class='align-middle' />
      </div>
    </b-card>
    <b-card v-else>
      <b-row>
        <b-col
          md='4'
          lg='3'
        >
          <h6 class='custom-line-clamp-one'>
            {{ $t('title') }}
          </h6>
          <p>
            {{ details.title_label }}
          </p>
          <hr>
        </b-col>
        <b-col
          md='4'
          lg='3'
        >
          <h6 class='custom-line-clamp-one'>
            {{ $t('status') }}
          </h6>
          <p>
            {{ details.status_value }}
          </p>
          <hr>
        </b-col>
        <b-col
          md='4'
          lg='3'
        >
          <h6 class='custom-line-clamp-one'>
            {{ $t('created_at') }}
          </h6>
          <p>
            {{ formatDate(details.created_at) }}
          </p>
          <hr>
        </b-col>
        <b-col
          md='12'
          lg='12'
        >
          <h6 class='custom-line-clamp-one'>
            {{ $t('labels.meta_description') }}
          </h6>
          <p>
            {{ details.meta_description }}
          </p>
          <hr>
        </b-col>
        <b-col
          v-if='details.body'
          md='12'
          lg='12'
        >
          <h6 class='custom-line-clamp-one'>
            {{ $t('labels.description') }}
          </h6>
          <div v-html='details.body[$i18n.locale]'></div>
          <hr>
        </b-col>
        <b-col
          v-if='details.image'
          md='4'
          lg='4'
        >
          <h6 class='custom-line-clamp-one'>
            {{ $t('labels.image') }}
          </h6>
          <div>
            <img
              class='img-fluid mb-2'
              style='max-height: 400px; object-fit: contain; width:100%'
              :src='details.image_path' alt='image'
            >
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import error from '@/views/pages/miscellaneous/Error.vue'

export default {
  data() {
    return {
      details: null,
      isDataLoaded:false
    }
  },
  created() {
    this.getDetails()
  },
  methods: {
    getDetails() {
      this.axios.get(`/pages-builder/${this.$route.params.resourceId}`)
        .then(res => {
          this.details = res.data.data
          this.isDataLoaded = true
        }).catch(error => {
        this.isDataLoaded = true
      })
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()

      return `${day < 10 ? `0${day}` : day}-${month < 10 ? `0${month}` : month}-${year}`
    },
  },
}
</script>
